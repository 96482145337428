<template>
    <div style="width: 100%">
        <div style="max-width: 100%;height: 320px;margin-bottom: 10px;margin-top: 110px;background: url('https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/cuka.png')">
            <div style="width: 1500px;margin: 10px auto;">
                <div style="display: flex;justify-content: center;align-items: center">
                    <div style="height: 170px;width: 100%;margin: 0 6%;line-height: 300px;font-weight: bold;font-size: 34px;color: white;position: relative;text-align: center;">
                         —— 通知公告 ——
                    </div>
                </div>
                <div style="display: flex;justify-content: center;align-items: center">
                    <span style="font-size: 14px;color:#FFFFFF;margin: 0 auto">NOTICES</span>
                </div>
            </div>
        </div>
        <div style="width: 1500px;margin: 0 auto;background: #FFFFFF;min-height: 600px;box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);">
<!--            <div style="float: right;margin: 20px 30px 0 0;"><el-button size="small" style="background:#031F88;color: #FFFFFF;width: 116px;font-size: 16px;">一键已读</el-button></div>-->
            <div v-for="(item,index) in announcementList" :key="index" style="min-height: 220px;width: 100%;">
                <div style="padding: 30px 0 0 40px;">
                    <div style="width: 100%;display: flex">
                        <div style="width: 3%">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bgb.png" style="width: 46px;height: 46px;border-radius: 50%;">
                        </div>
                        <div style="width: 95%">
                            <div style="float: left;margin-left: 20px;">
                                <div style="font-size: 16px;color: #666666;float: left">百工宝公告</div>
                                <div style="font-size: 16px;color: #999999;float: left;margin-left: 20px;">{{item.createTime}}</div>
                                <div style="clear: both;font-size: 18px;padding-top: 20px;font-weight: bold;cursor: pointer;">{{item.title}}
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/new.png" style="width: 20px;height: 20px;position: relative; top: 5px;">
                                </div>
                                <div style="font-size: 16px;color: #999999;margin-top: 14px;">{{item.content}}</div>
                             </div>
                        </div>
                    </div>
                    <div style="padding-top: 10px;"><el-divider></el-divider></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {


    },

    computed: {
        user() {
            return this.$store.state.user;
        },

    },
    created() {

    },
    data () {
        return {
            announcementList:[]
        }
    },
    mounted:function(){
        this.getAnnouncementList()
    },
    methods:{
        getAnnouncementList(){
            var that = this;
            that.newApi.getAnnouncementList({}).then((res)=>{
                if (res.isSuccess == 1) {
                    that.announcementList = res.data;
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
    }
}
</script>